<template>
  <div id="privacy-box" class="shop-window-index isMobile" style="min-height: 100vh;background: #fff">
      <div class="content" style="margin-top:0.3em;">
        <p style="text-indent: 0em;"><b>使用须知：</b></p>
        <p style="margin-top: .5em;">1、我们访问您的相册可以使您直接调取您手机中的照片或图片以进行评论、分享或与客服沟通时证明您所遇到的问题。我们会使用您所上传的照片来识别您需要购买的商品或使用包含您所上传图片的评价。</p>
        <p style="margin-top: .5em;">2、我们会采用符合业界标准的安全防护措施来防止您的个人信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们努力使用各种合理的制度、技术、程序以及物理层面的措施来保护您的个人信息不被未经授权的访问、篡改、披露或破坏，包括但不限于SSL传输加密技术、信息存储加密技术、数据中心的访问权限控制等技术或措施。</p>
        <p style="margin-top: .5em;">3、您可以通过“我的—设置—隐私设置”逐项查看您上述个人信息的访问权限开启状态，并可以决定开启或关闭这些权限（我们会指引您在您的设备系统中完成设置）。您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</p>
        <p style="margin-top: .5em;">4、关于我们对于您个人信息的收集、使用、共享的详尽方式以及我们所能提供的个人信息安全技术措施请您查看<span style="display: inline; color: #00AAA6;cursor: pointer;" @click="clickPushToPrivacy">《海信信天翁系统隐私政策》</span>。</p>
      </div>
  
    </div>
  </template>
  <script>
    export default {
        methods: {
            clickPushToPrivacy(){
                this.$router.push("/privacy");
            }
        }
    }
  </script>
  <style lang="less" src="./index.less" scoped></style>
  <!-- <script lang="javascript" src="./index.js"></script> -->
  